import axios from "axios";
import { useEffect, useRef, useState } from "react";
import Service, { url } from "../Service/Service";
import { Col, Form, Row } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { useExterior } from "../Context/ExteriorContext.";
import { useLoader } from "../Context/LoaderContext";
import { useAlreadyConfirmed } from "../Context/AlreadyConfirmed";

const CalleDireccion = (props: any) => {

    const [value, setValue] = useState("");

    useEffect(() => {
        setValue(props.res_direccion);
    }, []);

    return (
        <Form.Group
            className='mb-3'
            controlId='res_direccion'
        >
            <Form.Label>Dirección</Form.Label>
            <Form.Control
                onChange={(ev: any) => setValue(ev.target.value)}
                value={value}
                as='textarea'
                rows={1}
                autoComplete='off'
                // required={exterior}
                placeholder="CALLE | NO"
                name='res_direccion' />

        </Form.Group>
    )
}

export const LocalidadResNacional = (props: any) => {


    const { AlreadyConfirmed } = useAlreadyConfirmed();

    const [loaded, setLoaded] = useState(false);
    const loader = useLoader();

    const refProvincia = useRef(null as any);
    const refMunicipio = useRef(null as any);
    const refCirc = useRef(null as any);
    const refDm = useRef(null as any);
    const refSector = useRef(null as any);


    // TypeHead State
    const [provincia, setProvincia] = useState([] as any);
    const [circ, setCirc] = useState([] as any);
    const [municipio, setMunicipio] = useState([] as any);
    const [dm, setDm] = useState([] as any);
    const [sector, setSector] = useState([] as any);

    // Listas
    const [provincias, setProvincias] = useState([] as any);
    const [circs, setCircs] = useState([] as any);
    const [municipios, setMunicipios] = useState([] as any);
    const [dms, setDms] = useState([] as any);
    const [sectores, setSectores] = useState([] as any);


    // const { isExteriror } = useExterior();


    const handlerProvincia = async (e: any) => {
        if (e.length) {
            setProvincia(e);
            const json = await Service.circunscripcion(e[0]?.id);
            //   const json = await data.json();
            setCircs(json);
            refCirc.current?.clear();
            refMunicipio.current?.clear();
            refDm.current?.clear();
            refSector.current?.clear();
            setCirc([])
            setMunicipio([])
            setDm([])
            setSector([])
        } else {
            setProvincia([]);
        }

    };

    const handlerCirc = async (e: any) => {
        if (e.length) {
            setCirc(e);

            const data = await Service.municipio(provincia[0].id, e[0]?.id);
            setMunicipios(data);


            setMunicipio([])
            setDm([])
            setSector([])
            refMunicipio.current?.clear();
            refDm.current?.clear();
            refSector.current?.clear();
        } else {
            setCirc([]);
        }
    };

    const handlerMunicipio = async (e: any) => {
        if (e.length) {
            setMunicipio(e);

            const data = await Service.dm(e[0]?.id);
            setDms(data);


            const data2 = await Service.sector(e[0]?.id);
            setSectores(data2);


            setDm([])
            setSector([])
            refDm.current?.clear();
            refSector.current?.clear();
        } else {
            setMunicipio([]);
        }
    };

    const handlerDm = async (e: any) => {
        if (e.length > 0) {
            setDm(e);
            const data2 = await Service.sector(e[0]?.id);
            setSectores(data2);
            setSector([])
            refSector.current?.clear();
        } else {
            setDm([]);
        }


    };

    const handlerSector = async (e: any) => {
        if (e.length) {
            setSector(e);
        } else {
            setSector([]);
        }
    };

    const cargarProvincias = async () => {

        loader.startLoading();


        const provincias = await Service.provincias()
        setProvincias(provincias);

        if (props.provincia_dir) {
            let provincia = provincias.filter((v: any) => v.id == props.provincia_dir);
            setProvincia(provincia);
        }



        loader.finishLoading();
    }

    const cargarCircs = async () => {

        loader.startLoading();

        if (props.provincia_dir) {
            const circs = await Service.circunscripcion(props.provincia_dir);
            setCircs(circs);
            const circ = circs.filter((v: any) => v.id == '0' + props.circunscripcion_dir);
            setCirc(circ);
        }

        loader.finishLoading();
    }


    const cargarMunicipios = async () => {

        loader.startLoading();

        if (props.provincia_dir && props.circunscripcion_dir) {
            const municipios = await Service.municipio(
                props.provincia_dir,
                '0' + props.circunscripcion_dir
            )
            setMunicipios(municipios);


            const municipio = municipios.filter((v: any) => v.id == parseInt(props.municipio_dir));
            setMunicipio(municipio);
        }

        loader.finishLoading();
    }


    const cargarDistritosMunicipales = async () => {

        loader.startLoading();

        if (props.municipio_dir) {
            const distritos_municipales = await Service.dm(props.municipio_dir);
            setDms(distritos_municipales);
            setDm(distritos_municipales.filter((v: any) => v.id == props.distrito_municipal_dir));
        }

        loader.finishLoading();
    }


    const cargarSectores = async () => {

        loader.startLoading();

        if (props.municipio_dir || props.distrito_municipal_dir) {

            const municipio = props.distrito_municipal_dir ?
                props.distrito_municipal_dir : props.municipio_dir;

            const sectores = await Service.sector(municipio);
            setSectores(sectores);
            const sector = sectores.filter((p: { id: number; }) => p.id == parseInt(props.codigo_sector));
            // debugger;
            setSector(sector);
        }

        loader.finishLoading();
    }

    useEffect(() => {

        const fetchData = async () => {
            await Promise.allSettled([
                cargarProvincias(),
                cargarCircs(),
                cargarMunicipios(),
                cargarDistritosMunicipales(),
                cargarSectores()]
            );

            setLoaded(true);
        }

        fetchData();

    }, []);

    // console.log({
    //     provincia,
    //     circ,
    //     municipio,
    //     dm,
    //     sector,
    // });

    const { isExteriror } = useExterior();

    if (!loaded || isExteriror) return null;

    return (
        <div>
            <input readOnly name="exterior_dir" value={isExteriror ? "1" : "0"} hidden />
            <input readOnly name="provincia_dir" value={provincia.length > 0 ? provincia[0]?.id : ""} hidden />
            <input readOnly name="circunscripcion_dir" value={circ.length > 0 ? circ[0]?.id : ""} hidden />
            <input readOnly name="municipio_dir" value={municipio.length > 0 ? municipio[0]?.id : ""} hidden />
            <input readOnly name="distrito_municipal_dir" value={dm.length > 0 ? dm[0]?.id : ""} hidden />
            <input readOnly name="codigo_sector" value={sector.length > 0 ? sector[0]?.id : ""} hidden />

            <Row>
                <Col className='mb-3' lg={6}>

                    <Form.Group>
                        <Form.Label>Provincia</Form.Label>
                        <Typeahead
                            disabled={AlreadyConfirmed}
                            id='provincia_dir-id'
                            ref={refProvincia}
                            // selected={provincia}
                            defaultSelected={provincia}

                            clearButton
                            onChange={handlerProvincia}
                            labelKey='descripcion'
                            placeholder='Seleccionar...'
                            options={provincias}
                        // inputProps={{name:"provincia"}}
                        // inputProps={{ required: !exterior ? true : false }}
                        // value={person?.IdProvincia}
                        />
                    </Form.Group>
                </Col>

                <Col className='mb-3' lg={6}>

                    <Form.Group>
                        <Form.Label>Circ.</Form.Label>
                        <Typeahead
                            disabled={AlreadyConfirmed}
                            id='res_circ-id'
                            clearButton
                            ref={refCirc}
                            defaultSelected={circ}
                            onChange={handlerCirc}
                            // labelKey={'descripcion'}
                            labelKey={(circs: any) => `${circs?.descripcion}`}
                            placeholder='Seleccionar...'
                            options={circs}

                        // inputProps={{name:"circunscripcion"}}
                        // inputProps={{ required: false }}
                        />
                    </Form.Group>
                </Col>
            </Row>

            <Row>
                <Col className='mb-3' lg={6}>

                    <Form.Group>
                        <Form.Label>Municipio</Form.Label>
                        <Typeahead
                            disabled={AlreadyConfirmed}
                            clearButton
                            ref={refMunicipio}
                            defaultSelected={municipio}
                            id='municipio_dir-id'
                            onChange={handlerMunicipio}
                            labelKey='descripcion'
                            placeholder='Seleccionar...'
                            options={municipios}
                        // inputProps={{ name: "municipio" }}
                        // inputProps={{ required: !exterior ? true : false }}
                        // selected={provincia}
                        />
                    </Form.Group>
                </Col>
                <Col className='mb-3' lg={6}>
                    <Form.Group>
                        <Form.Label>Distrito Municipal</Form.Label>
                        <Typeahead
                            disabled={AlreadyConfirmed}
                            id='res_dm-id'
                            clearButton
                            ref={refDm}
                            defaultSelected={dm}
                            onChange={handlerDm}
                            labelKey='descripcion'
                            placeholder='Seleccionar...'
                            options={dms}
                        // inputProps={{name:"distrito_municipal"}}
                        // selected={provincia}
                        />
                    </Form.Group>
                </Col>
            </Row>

            <Row>
                <Col className='mb-3' lg={6}>
                    <Form.Group>
                        <Form.Label>Sector</Form.Label>
                        <Typeahead
                            disabled={AlreadyConfirmed}
                            id='sector-id'
                            clearButton
                            ref={refSector}
                            defaultSelected={sector}
                            onChange={handlerSector}
                            labelKey='descripcion'
                            placeholder='Seleccionar...'
                            options={sectores}
                        // inputProps={{name:"IdSectorParaje"}}
                        // selected={provincia}
                        />
                    </Form.Group>
                </Col>
                <Col className='mb-3' lg={6}>


                </Col>
            </Row>
        </div>
    )
}