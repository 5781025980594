import React from 'react'

function Footer() {
    return (
        <footer id='footer' className='pb-1'>
            <p
                className='text-center text-muted pt-2 foop  '
                style={{ borderTop: '1px solid #e9e9e9', marginTop: '0 !important' }}
            >
                Desarrollado por: Secretaría de Tecnologías
            </p>
        </footer>
    )
}

export default Footer