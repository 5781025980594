import { useEffect, useState } from "react";
import Service from "../Service/Service";
import { useCargoLocalidad } from "../Context/CargoLocalidadContext";
import { useAspirante } from "../Context/AspiranteContext";


const useCargos = () => {

    const [cargos, setCargos] = useState([]);
    const [cargo, setCargoState] = useState([] as any);

    const { aspirante } = useAspirante();

    const setCargo = (e:any) =>{
        if(e.length){
            setCargoState(e);
        }else{
            setCargoState([]);
        }
    }

    useEffect(() => {
        Service.getCargos()
            .then((data:any) => {
                setCargos(data);
            });
    }, [])

    useEffect(() => {
        
            setCargoState(cargos?.filter((v: { id: any; })=> v.id == aspirante?.tipo_id ));
    }, [aspirante])

    return { cargos, cargo, setCargo };
};

export default useCargos;