import { Form } from "react-bootstrap";

export function TextInput({ value, name, label, ...props }: any) {

    const labelDesc = label ? label : name;

    return (
        <Form.Group className='mb-3' controlId={`${labelDesc}-id`}>
            <Form.Label>{labelDesc}</Form.Label>
            <Form.Control
                type='text'
                required
                defaultValue={value}
                name={name}
                {...props}
            />
        </Form.Group>
    )
}