import React from "react";

import styles from "./LoaderOverlay.module.css";
import { useLoader } from "../Context/LoaderContext";

const LoaderOverlay: React.FC = () => {
  const { loadingCount } = useLoader();

  if (loadingCount === 0) {
    return null; // Render nothing if not loading
  }

  return (
    <div className={`position-fixed w-100 h-100 ${styles.overlay}`}>
      <div className={`spinner-border text-success ${styles.spinner}`} role="status">
        <span className="visually-hidden">Porfavor espere...</span>
      </div>
    </div>
  );
};

export default LoaderOverlay;
