import React from 'react'

function PageTitle2({ title }: any) {
    return (
        <h6 >
            {title}
        </h6>
    );
}

export default PageTitle2