export function RadioInput({ value, name, id, ...props }: any) {
    
    return (
        <div className="form-check form-check-inline">
            <input
                className="form-check-input"
                type="radio"
                id={id}
                value={value}
                name={name}
                {...props}
            />
            <label className="form-check-label" htmlFor={id}>
                {props.label}
            </label>
        </div>
    )
}