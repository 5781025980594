import React, { createContext, useState, useContext, ReactNode, useEffect } from "react";
import { useDireccionCentral } from "./DireccionCentralContext";

interface AlreadyConfirmedContextProps {
  AlreadyConfirmed: any;
  setAlreadyConfirmed: React.Dispatch<any>;
}

const AlreadyConfirmedContext = createContext<AlreadyConfirmedContextProps>({} as any);

interface AlreadyConfirmedProviderProps {
  children: ReactNode;
}

const AlreadyConfirmedProvider: React.FC<AlreadyConfirmedProviderProps> = ({ children }) => {

  const [AlreadyConfirmed, setAlreadyConfirmed] = useState<any>(false);
  const { direccionCentral } = useDireccionCentral();


  useEffect(() => {
    if (direccionCentral?.confrimada_antes) {
      setAlreadyConfirmed(true);
    }
  }, [direccionCentral])

  const value = {
    AlreadyConfirmed,
    setAlreadyConfirmed,
  };

  return (
    <AlreadyConfirmedContext.Provider value={value}>
      {children}
    </AlreadyConfirmedContext.Provider>
  );
};

export const useAlreadyConfirmed = () => useContext(AlreadyConfirmedContext);

export { AlreadyConfirmedProvider };