import React, { createContext, useState, useContext, ReactNode, useEffect } from "react";
import { currentEnviroment } from "../Service/Service";

interface ComesFromSysgelContextProps {
  ComesFromSysgel: any;
  setComesFromSysgel: React.Dispatch<any>;
}

const ComesFromSysgelContext = createContext<ComesFromSysgelContextProps>({} as any);

interface ComesFromSysgelProviderProps {
  children: ReactNode;
}

const ComesFromSysgelProvider: React.FC<ComesFromSysgelProviderProps> = ({ children }) => {

  const [ComesFromSysgel, setComesFromSysgel] = useState<any>(false);

  const value = {
    ComesFromSysgel,
    setComesFromSysgel,
  };

  useEffect(() => {
    // Check if the referer matches a specific URL
    // console.log(document.referrer);
    if (document.referrer?.includes(currentEnviroment['base'])) {
      setComesFromSysgel(true);
      // Perform actions or logic if the referer matches
      // console.log('Coming from the specific referer');
     
    } else {
      // Perform actions or logic if the referer does not match
      // console.log('Not coming from the specific referer');
    }
  }, [])

  return (
    <ComesFromSysgelContext.Provider value={value}>
      {children}
    </ComesFromSysgelContext.Provider>
  );
};

export const useComesFromSysgel = () => useContext(ComesFromSysgelContext);

export { ComesFromSysgelProvider };
