import { Col, Row } from "react-bootstrap"
import { TextInput } from "../Components/TextInput"
import { useAlreadyConfirmed } from "../Context/AlreadyConfirmed";

export const Direccion = (props: any) => {
    const {AlreadyConfirmed} = useAlreadyConfirmed();

    return (
        <div>
            <Row>
                <Col md={6}>
                    <TextInput disabled={AlreadyConfirmed} value={props?.calle} name={"calle"} label={"Calle/Avenida"} />
                </Col>
                <Col md={6}>
                    <TextInput disabled={AlreadyConfirmed} value={props?.residencial} name={"residencial"} label={"Residencial/Torre"} />
                </Col>
            </Row>

            <Row>
                <Col md={6}>
                    <TextInput disabled={AlreadyConfirmed} value={props?.numero} name={"numero"} label={"Número"} />
                </Col>
                <Col md={6}>
                    <TextInput disabled={AlreadyConfirmed} value={props?.apartamento} name={"apartamento"} label={"Apto"} />
                </Col>
            </Row>
        </div>
    )
}
