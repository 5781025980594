import axios from "axios";

const eviroments = {
  dev: {
    base: 'http://localhost/',
    url: 'http://localhost/frontend/web/api',
    files_upload_base: 'http://localhost/frontend/web/api/eleccion-candidato/get-uploads?url-path='
  },
  prod: {
    base: 'sysgel.net',
    url: 'https://sysgel.net/frontend/api',
    files_upload_base: 'https://sysgel.net/frontend/web/api/eleccion-candidato/get-uploads?url-path='
  }
};

export const currentEnviroment = eviroments['prod'];

export const url = currentEnviroment['url'];
export const files_upload_base = currentEnviroment['files_upload_base'];


const Service = {
  oficios: async () => {
    try {
      const data = await axios({
        url: `${url}/proyecciones/profesiones`,
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });

      if (data.status === 200 && data.data) {
        return data.data;
      }
    } catch (error) {
      console.log(error);
    }
  },
  getPaises: async (circunscripcion: any) => {
    try {
      // const resp = await axios({
      //   url: `${url}/proyecciones/paises?circunscripcion=${circunscripcion}`,
      //   method: 'GET',
      //   headers: {
      //       Accept: 'application/json',
      //       'Content-Type': 'application/x-www-form-urlencoded',
      //       'Access-Control-Allow-Origin': '*',
      //   },
      //   // data: data,
      // });

      let resp = await fetch(`${url}/proyecciones/paises?circunscripcion=${circunscripcion}`);

      return resp.json();
    } catch (error) {
      console.log(error);
    }

  },
  getNivel1: async (exteriorCirc: any, paisId: any) => {
    try {
      const resp = await axios({
        url: `${url}/proyecciones/estados?circunscripcion=${exteriorCirc}&pais=${paisId}`,
        method: 'GET',
        // headers: {
        //     Accept: 'application/json',
        //     'Content-Type': 'application/x-www-form-urlencoded',
        //     'Access-Control-Allow-Origin': '*',
        // },
        // data: data,
      });
      return resp.data;
    } catch (error) {
      console.log(error);
    }
    return [];
  },
  getNivel2: async (estadoId: any, paisId: any) => {
    try {
      const resp = await axios({
        url: `${url}/proyecciones/condados?estado=${estadoId}&pais=${paisId}`,
        method: 'GET',
        // headers: {
        //     Accept: 'application/json',
        //     'Content-Type': 'application/x-www-form-urlencoded',
        //     'Access-Control-Allow-Origin': '*',
        // },
        // data: data,
      });
      return resp.data;
    } catch (error) {
      console.log(error);
    }
    return [];
  },
  getNivel3: async (condadoId: any, paisId: any) => {
    try {
      const resp = await axios({
        url: `${url}/proyecciones/ciudades?condado=${condadoId}&pais=${paisId}`,
        method: 'GET',
        // headers: {
        //     Accept: 'application/json',
        //     'Content-Type': 'application/x-www-form-urlencoded',
        //     'Access-Control-Allow-Origin': '*',
        // },
        // data: data,
      });
      return resp.data;
    } catch (error) {
      console.log(error);
    }
    return [];
  },
  getCedulaInfo: async (data: { cedula: any }) => {
    const resp = await axios({
      url: `${url}/direccion-central/validar?cedula=${data.cedula}`,
      method: 'GET'
    });
    return resp;

  },
  updateCandidato: async (data: any,cedula:string,dob:string) => {
    try {
      const resp = await axios({
        url: `${url}/direccion-central/guardar?cedula=${cedula}&dob=${dob}`,
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        data
      });
      if (resp.status === 200 && resp.data) {
        return resp.data;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  },

  getCargos: async () => {
    try {
      const resp = await axios({
        url: `${url}/eleccion-candidato/cargos`,
        method: 'GET',
        // headers: {
        //     Accept: 'application/json',
        //     'Content-Type': 'application/x-www-form-urlencoded',
        //     'Access-Control-Allow-Origin': '*',
        // },
        // data: data,
      });
      if (resp.status === 200) {
        return resp.data;
      }
    } catch (error) {
      console.log(error);
    }
    return [];
  },

  provincias: async (id = 0) => {
    try {
      const data = await axios({
        url: `${url}/proyecciones/provincias`,
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });

      if (data.status === 200) {
        if (id !== 0 && data.data.length > 0) {
          const newData = data.data.filter((p: { id: string; }) => p.id === `${id}`);
          return newData;
        }
        return data.data;
      }
    } catch (error) {
      console.log(error);
      return [];
    }
  },

  circunscripcion: async (provincia: any, id: any = 0) => {
    try {
      const data = await axios({
        url: `${url}/proyecciones/circunscripciones?provincia=${provincia}`,
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });

      if (data.status === 200) {
        if (id !== 0 && data.data.length > 0) {
          const newData = data.data.filter((p: { id: string; }) => p.id === `${id}`);
          return newData;
        }
        return data.data;
      }
    } catch (error) {
      console.log(error);
      return [];
    }
  },

  municipio: async (provincia: any, circ: any, id = 0) => {
    try {
      const data = await axios({
        url: `${url}/proyecciones/municipios?provincia=${provincia}&circunscripcion=${circ}`,
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });

      if (data.status === 200) {
        if (id !== 0 && data.data.length > 0) {
          // console.log(data.data);
          const newData = data.data.filter((p: { id: number; }) => p.id === id);
          return newData;
        }
        return data.data;
      }
    } catch (error) {
      console.log(error);
      return [];
    }
  },

  dm: async (municipio: any, id = 0) => {
    try {
      const data = await axios({
        url: `${url}/proyecciones/distritos-municipales?municipio=${municipio}`,
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });

      if (data.status === 200) {
        if (data.data.length > 0) {
          if (id !== 0) {
            // console.log(municipio);
            // console.log(data.data);
            const newData = data.data.filter((p: { id: number; }) => p.id === id);
            return newData;
          }
          // console.log(data.data);
          return data.data;
        } else return [];
      }
    } catch (error) {
      console.log(error);
      return [];
    }
  },

  sector: async (municipio: any, id = 0) => {
    try {
      const data = await axios({
        url: `${url}/proyecciones/sectores?municipio=${municipio}`,
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });

      if (data.status === 200) {
        if (id !== 0 && data.data.length > 0) {
          // console.log(data.data);
          // console.log(id)
          const newData = data.data.filter((p: { id_sector: number; }) => p.id_sector === id);
          // console.log(newData);
          return newData;
        }
        return data.data;
      }
    } catch (error) {
      console.log(error);
      return [];
    }
  },
  circExterior: async () => {
    try {
      const data = await axios({
        url: `${url}/proyecciones/circunscripciones-exteriores`,
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });

      if (data.status === 200 && data.data) {
        return data.data;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  },
  photo: async (cedula: any) => {
    try {
      const data = await axios({
        url: `${url}/public-form/photo?cedula=${cedula}`,
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });

      if (data.status === 200 && data.data) {
        // console.log(data.data)
        return data.data;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  },
  partidos: async (id: any = 0) => {
    try {
      const data = await axios({
        url: `${url}/eleccion-candidato/partidos`,
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });

      if (data.status === 200 && data.data) {
        if (id !== 0 && data.data.length > 0) {
          // console.log(data.data);
          // console.log(id)
          const newData = data.data.filter((p: any) => p.id === id);
          // console.log(newData);
          return newData;
        }
        return data.data;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  },
 
  documentosDesc: async function () {
    try {
      const data = await axios({
        url: `${url}/eleccion-candidato/documentos-desc`,
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });

      if (data.status === 200 && data.data) {
        return data.data;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  },
  statusLabels: async function () {
    try {
      const data = await axios({
        url: `${url}/eleccion-candidato/status-labels`,
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });

      if (data.status === 200 && data.data) {
        return data.data;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  }
}

export default Service;