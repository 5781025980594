import React, { createContext, useContext, useRef } from "react";
import Service from "../Service/Service";
import { useLoader } from "./LoaderContext";
import { useDireccionCentral } from "./DireccionCentralContext";
import { useAlreadyConfirmed } from "./AlreadyConfirmed";

interface AspiranteSaveFormContextProps {
  formRef: React.MutableRefObject<any>;
  handleSubmit: (reason: any) => any;
}

const AspiranteSaveFormContext = createContext<AspiranteSaveFormContextProps>({} as any);



const AspiranteSaveFormProvider: React.FC<any> = ({ children }) => {

  const formRef = useRef(null as any);
  const {direccionCentral} = useDireccionCentral();
  

  const handleSubmit = async (reason: string) => {
    const form = formRef.current;
    const formData = new FormData(form);
    formData.append('reason',"Actualización Datos Dirección Central, Convención de Dirigentes");
    const data = Object.fromEntries(formData.entries());
    
    // console.log({ data });
    // return false
    // data['reason'] = reason;
    const {cedula,fecha_nacimiento} = direccionCentral.fp;
    const resp = await Service.updateCandidato(formData,cedula,fecha_nacimiento);

    return resp;
  };


  const value: AspiranteSaveFormContextProps = {
    formRef,
    handleSubmit
  }


  return (
    <AspiranteSaveFormContext.Provider value={value}>
      <form ref={formRef} id="main_form">
        {children}
      </form>
    </AspiranteSaveFormContext.Provider>
  );
};

export const useAspiranteSaveForm = () => useContext(AspiranteSaveFormContext);

export { AspiranteSaveFormProvider };
