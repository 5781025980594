import { Container, Nav, Navbar } from 'react-bootstrap'
import Logo from '../img/logo2.png';

function Header() {
  return (
    <Navbar
      collapseOnSelect
      expand='lg'
      style={{ backgroundColor: '#FFFFFF', borderBottom: '1px solid #e9e9e9' }}
    >
      <Container>
        <Navbar.Brand href='/'>
          <img src={Logo} alt='form' style={{ maxHeight: '50px' }} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls='responsive-navbar-nav' />
        <Navbar.Collapse className='justify-content-end'>
          <Nav>
            {/* <Nav.Link href='/'>Inicio</Nav.Link>
            <Nav.Link href='https://sysgel.net/frontend/web/eleccion-digital/agregar-candidatos-exterior?eleccion_id=3'>
              Regístrese aquí
            </Nav.Link> */}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default Header