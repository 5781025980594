import { useEffect, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import useCargos from '../Hooks/useCargos';
import Service from '../Service/Service';

import Foto from './Foto';
import { ExteriorProvider, useExterior } from '../Context/ExteriorContext.';
import ReasonForChangeDialog from '../ReasonForChangeDialog/ReasonForChangeDialog';
import { AspiranteSaveFormProvider } from '../Context/AspiranteSaveFormContext';
import { ReasonForChangeProvider } from '../Context/ReasonForChangeContext';
import InputMask from "react-input-mask";
import { useHaSidoCandidato } from '../Context/HaSidoCandidatoContext';
import { useCargoLocalidad } from '../Context/CargoLocalidadContext';

import { LocalidadResExterior } from './LocalidadResExterior';
import { LocalidadResNacional } from './LocalidadResNacional';
import { ProfecionComponent } from './Profecion';
import { StatusDocumentosEdit } from './StatusDocumentosEdit';
import { StatusDocumentos } from './StatusDocumentos';
import { LocalidadDatoElectoralNacional } from './LocalidadDatoElectoralNacional';
import { LocalidadExterior } from './LocalidadExterior';
import { TextInput } from '../Components/TextInput';
import { Direccion } from './Direccion';
import { RedesSociales } from './RedesSociales';
import { TrabajaRadioInput } from './TrabajaRadioInput';
import { PartidoAnteriorRadioInput } from './PartidoAnteriorRadioInput';
import { calculateAge } from '../Helper/calculateAge';
import { useAlreadyConfirmed } from '../Context/AlreadyConfirmed';
import { PhoneNumberInput } from '../Components/PhoneNumberInput';
import es from 'react-phone-input-2/lang/es.json'
import PhoneInput from 'react-phone-input-2';


const CedulaInput = ({ cedula }: any) => {
    return (
        <Form.Group className='mb-3' controlId='Cedula'>
            <Form.Label>Cédula</Form.Label>
            <InputMask
                className='form-control'
                mask='999-9999999-9'
                maskChar=''
                value={cedula}
                required
                alwaysShowMask={false}
                disabled={true}
                onChange={() => { }}
                id="cedula"
            />
        </Form.Group>
    );
}

const ExteriorSwitch = () => {
    const { isExteriror, setExteriror } = useExterior();

    return (
        <Row className='mb-3'>
            <Col md={12}>
                <Form.Check
                    onChange={(e) => setExteriror(e.target.checked)}
                    // reverse
                    type='switch'
                    id='custom-switch'
                    label='Exterior'
                    checked={isExteriror}
                />
            </Col>
        </Row>
    )
}

function EqualityValidatorCelPhone(props: any) {
    const [value, setValue] = useState("");
    const [confirmationValue, setConfirmationValue] = useState("");
    const { AlreadyConfirmed } = useAlreadyConfirmed();
    const fieldsDisabled = AlreadyConfirmed;


    useEffect(() => {
        // const initialValue = compareTo ? compareTo.replace(/[^0-9]/g, "") : "";
        if (AlreadyConfirmed) {
            setValue(props?.value);
            setConfirmationValue(props?.value);
        }
    }, [AlreadyConfirmed])

    const isEqual = confirmationValue == value;

    const RedIndicatorJSX = <span style={{ color: 'red' }}>*</span>;

    return (
        <Row>

            <Col md={6}>
                <input value={"+" + value?.replace(/[^0-9]/, "")} name={"celular"} readOnly hidden />
                <label className="form-label" htmlFor={"celular-id"}>
                    <span>Celular {RedIndicatorJSX}</span>
                </label>
                <PhoneInput
                    enableSearch={!fieldsDisabled}
                    disabled={fieldsDisabled}
                    countryCodeEditable={false}
                    localization={es}
                    placeholder="Ingrese Número"
                    onChange={setValue as any}
                    value={value}
                    preferredCountries={['do', 'es', 'us']}
                    autoFormat={false}
                    inputProps={{
                        id: "celular-id"
                    }}
                />
            </Col>
            <Col md={6}>
                <input value={"+" + confirmationValue?.replace(/[^0-9]/, "")} name={"confirmar-celuar"} readOnly hidden />
                <label className="form-label" htmlFor={"confirmar-celular-id"}>
                    <span>Celular (Confirme su número de celular	) {RedIndicatorJSX}</span>
                </label>
                <PhoneInput
                    isValid={isEqual}
                    disabled={fieldsDisabled}
                    enableSearch={!fieldsDisabled}
                    countryCodeEditable={false}
                    localization={es}
                    placeholder="Ingrese Número"
                    onChange={setConfirmationValue as any}
                    value={confirmationValue}
                    preferredCountries={['do', 'es', 'us']}
                    autoFormat={false}
                    inputProps={{
                        id: "confirmar-celular-id"
                    }}
                />
            </Col>
        </Row>
    )
}


function Informacion(props: any) {

    const { AlreadyConfirmed } = useAlreadyConfirmed();



    return (
        <AspiranteSaveFormProvider>
            <Row>
                <Col md={3}>
                    <Foto {...props?.fp} />
                </Col>
                <Col md={9}>
                    <Row>
                        <Col md={6}>
                            <CedulaInput {...props?.fp} />
                        </Col>
                        <Col md={6}>
                            <TextInput disabled={AlreadyConfirmed} value={props?.fp?.apodo} name="apodo" label="Nombre Boleta (Apodo)" />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <TextInput value={props?.fp?.nombres} label="Nombres" disabled={true} />
                        </Col>
                        <Col md={6}>
                            <TextInput value={props?.fp?.apellidos} label="Apellidos" disabled={true} />
                        </Col>
                    </Row>

                    <Row className='no-gutters'>
                        <Col md={3} xs={4}>
                            <TextInput value={props?.fp?.id} label="ID" disabled={true} />
                        </Col>
                        <Col md={2} xs={4}>
                            <TextInput value={props?.fp?.sexo} label="Sexo" disabled={true} />
                        </Col>

                        <Col md={2} xs={4}>
                            <TextInput value={calculateAge(props?.fp?.fecha_nacimiento)} label="Edad" disabled={true} />
                        </Col>

                        <Col md={5}>
                            <TextInput value={props?.fp?.date?.split(" ")[0]} label="Fecha registro" disabled={true} />
                        </Col>
                    </Row>

                </Col>
            </Row>


            <Row>
                <Col md={6}>
                    <TextInput disabled={AlreadyConfirmed} value={props?.fp?.email} name="email" label="Email" />
                </Col>
                <Col md={6}></Col>
            </Row>

            <EqualityValidatorCelPhone value={props?.fp?.celular} />

            <ExteriorProvider>
                <Row className='mt-3'>
                    <Col className='d-flex justify-content-between'>
                        <h5>Dirección Residencia:</h5>
                        {!AlreadyConfirmed && <ExteriorSwitch />}
                    </Col>
                </Row>
                <hr />

                <LocalidadResExterior {...props?.fp} />
                <LocalidadResNacional {...props?.fp} />
                <Direccion {...props?.direccion} />
            </ExteriorProvider>


            <Row className='mt-3'>
                <Col>
                    <h5>Redes Sociales:</h5>
                    <hr />
                </Col>
            </Row>
            <RedesSociales {...props?.fp} />
            {/* <Row>
                <Col md={6}>
                    <ProfecionComponent {...props?.fp} />
                </Col>
                <Col md={6}>
                    <TrabajaRadioInput {...props?.fp} />
                </Col>
            </Row> */}









            <Row className='mt-3'>
                <Col>
                    <h5>Dato Electoral:</h5>
                    <hr />
                </Col>
            </Row>
            <LocalidadDatoElectoralNacional {...props} />





            <Row className='mt-3'>
                <Col>
                    <h5>Dato Partidario:</h5>
                    <hr />
                </Col>
            </Row>
            <PartidoAnteriorRadioInput {...props?.fp} />

            <hr />
            <ReasonForChangeProvider>
                <ReasonForChangeDialog />
            </ReasonForChangeProvider>


        </AspiranteSaveFormProvider>
    )
}

export default Informacion