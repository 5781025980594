import React, { createContext, useState, useContext, ReactNode, useEffect } from "react";
import { useAspirante } from "./AspiranteContext";
import { useDireccionCentral } from "./DireccionCentralContext";


interface ExteriorContextProps {
    isExteriror: boolean;
    setExteriror: (state: boolean) => void;
    setExteriorFixed: React.Dispatch<React.SetStateAction<boolean>>;
}

const ExteriorContext = createContext<ExteriorContextProps>({} as any);

interface ExteriorProviderProps {
    children: ReactNode;
}

const ExteriorProvider: React.FC<ExteriorProviderProps> = ({ children }) => {

    
    const [isExteriorFixed, setExteriorFixed] = useState(false);
    const [isExteriror, setExterior] = useState(false);
    const { direccionCentral } = useDireccionCentral();


    const setExteriror = (state:boolean) => {
        if(isExteriorFixed) return;
        setExterior(state);
    }

    useEffect(() => {
        if (direccionCentral && direccionCentral?.fp?.exterior_dir == "1") {
            setExteriror(true);
        }
    }, [direccionCentral])

    // console.log({ isExteriror });
    const value = {
        isExteriror,
        setExteriror,
        setExteriorFixed
    };

    return (
        <ExteriorContext.Provider value={value}>
            {children}
        </ExteriorContext.Provider>
    );
};

const useExterior = () => useContext(ExteriorContext);

export { ExteriorProvider, useExterior };
