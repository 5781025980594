import { Col, Row } from "react-bootstrap";
import { TextInput } from "../Components/TextInput";





const Nacional = (props:any) => {
    console.log("",props)
    return <>
    <Row>
        <Col md={6}>
            <TextInput value={props?.Provincia} disabled label="Provincia"  />
        </Col>
        <Col md={6}>
        <TextInput value={props?.Circunscripcion} disabled label="Circunscripcion"  />
        </Col>
    </Row>
    <Row>
        <Col md={6}>
        <TextInput value={props?.Municipio} disabled  label="Municipio" />
        </Col>
        <Col md={6}>
        <TextInput value={props?.Recinto} disabled label="Recinto"  />
        </Col>
    </Row>
    <Row>
        <Col md={6}></Col>
        <Col md={6}>
             <TextInput value={props?.Colegio} disabled label="Colegio"  />
        </Col>
    </Row>
    </>
}

const Exterior = (props:any) => {
    return <>
    <Row>
        <Col md={6}>
            <TextInput value={props?.Circ} disabled label="Circ."  />
        </Col>
        <Col md={6}>
        <TextInput value={props?.Pais} disabled label="Pais"  />
        </Col>
    </Row>
    <Row>
        <Col md={6}>
        <TextInput value={props?.Estado} disabled  label="Estado/C.Autónoma" />
        </Col>
        <Col md={6}>
        <TextInput value={props?.Condado} disabled label="Condado/Provincia"  />
        </Col>
    </Row>
    <Row>
        <Col md={6}>
        <TextInput value={props?.Ciudad} disabled label="Ciudad"  />
        </Col>
        <Col md={6}>
           
        </Col>
    </Row>
    </>
}


export const LocalidadDatoElectoralNacional = (props: any) => {

    if(props?.fp?.codigo_pais){
        return <Exterior {...props?.dato_electoral} />;
    }

    return (<Nacional {...props?.dato_electoral} />);
}
