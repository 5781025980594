import React, { createContext, useState, useContext, ReactNode } from "react";
import useCheckCedula from "../Hooks/useCheckCedula";

interface DireccionCentralContextProps {
    direccionCentral: any;
    setDireccionCentral: React.Dispatch<any>;
}

const DireccionCentralContext = createContext<DireccionCentralContextProps>({} as any);

interface DireccionCentralProviderProps {
  children: ReactNode;
}

const DireccionCentralProvider: React.FC<DireccionCentralProviderProps> = ({ children }) => {

  const [direccionCentral, setDireccionCentral] = useState<any>(null);

  const value = {
    direccionCentral,
    setDireccionCentral,
  };

  return (
    <DireccionCentralContext.Provider value={value}>
      {children}
    </DireccionCentralContext.Provider>
  );
};

export const useDireccionCentral = () => useContext(DireccionCentralContext);

export { DireccionCentralProvider };
