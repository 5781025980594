import React from 'react'

function PageTitle1({ title }: any) {
    return (
        <h4 >
            {title}
        </h4>
    );
}

export default PageTitle1