import React, { createContext, useState, useContext, ReactNode, useEffect } from "react";
import { useAspirante } from "./AspiranteContext";
import { cargoLocations } from "../Objects/CargoLocalidad";
import useCargos from "../Hooks/useCargos";


interface CargoLocalidadContextProps {
    isPresidente: () => any;
    cargoLocalidad: {};
    currentCargoHasKey: (key: string) => boolean;
    cargos: never[];
    cargo: any;
    setCargo: (e: any) => void;
}

const CargoLocalidadContext = createContext<CargoLocalidadContextProps>({} as any);

interface CargoLocalidadProviderProps {
    children: ReactNode;
}

const CargoLocalidadProvider: React.FC<CargoLocalidadProviderProps> = ({ children }) => {




    const [cargoLocalidad, setCargoLocalidad] = useState({});

    const { aspirante } = useAspirante();

    const { cargos, cargo, setCargo } = useCargos();

    const currentCargoHasKey = (key: string) => {
        if(cargo.length == 0) return false;
        return cargo[0][key] != "0"
    }

    const isPresidente = () => {
        if(cargo.length == 0) return false;
        return cargo[0]?.name?.includes("Presidente");
    }

    // useEffect(() => {
    //     handleLocations(cargoId)
    // }, [aspirante, cargoId]);

    // console.log({ cargoLocalidad });

    const value = {
        isPresidente,
        cargoLocalidad,
        currentCargoHasKey,
        cargos, cargo, setCargo
    };

    return (
        <CargoLocalidadContext.Provider value={value}>
            {children}
        </CargoLocalidadContext.Provider>
    );
};

const useCargoLocalidad = () => useContext(CargoLocalidadContext);

export { CargoLocalidadProvider, useCargoLocalidad };
