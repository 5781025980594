import { useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { useReasonForChange } from '../Context/ReasonForChangeContext';
import { useAspiranteSaveForm } from '../Context/AspiranteSaveFormContext';
import { useLoader } from '../Context/LoaderContext';
import Swal from "sweetalert2";
import { useNavigate } from 'react-router-dom';
import { url } from '../Service/Service';
import { useAspirante } from '../Context/AspiranteContext';
import { useComesFromSysgel } from '../Context/ComesFromSysgelContext';
import { useAlreadyConfirmed } from '../Context/AlreadyConfirmed';
import { useDireccionCentral } from '../Context/DireccionCentralContext';
import { Col, Container, Row } from 'react-bootstrap';


function ReasonForChangeDialog() {

    const formRef = useRef();
    const { direccionCentral } = useDireccionCentral();
    const cofirmationLink = `https://sysgel.net/frontend/web/carnet2023/direccion-central?cedula=${direccionCentral?.fp?.cedula}`;



    const { handleSubmit } = useAspiranteSaveForm();

    const { show, handleClose, handleShow } = useReasonForChange();

    const [reason, setReason] = useState("");

    const isReasonEmpty = reason.trim() === "";

    const loader = useLoader();


    const { AlreadyConfirmed, setAlreadyConfirmed } = useAlreadyConfirmed();
    // const navigate = useNavigate();


    // console.log({ formRef });


    const runValidation = async () => {
        const form = document.querySelector("#main_form");
        if (!form) return;
        const formData = new FormData(form as any);

        const data = Object.fromEntries(formData.entries());

        const phoneWasNotFilled = String(data['celular']).length < 4;

        const phoneWasNotCofirmed = String(data['celular']) != String(data['confirmar-celuar']);


        if (phoneWasNotCofirmed) {
            await Swal.fire({
                icon: 'error',
                title: '¡Oops!',
                text: 'Se produjo un error. Confirme su Número De Celular',
                confirmButtonColor: '#039D53'
            });
            return false;
        }

        if (phoneWasNotFilled) {
            await Swal.fire({
                icon: 'error',
                title: '¡Oops!',
                text: 'Se produjo un error. Revise su Número De Celular',
                confirmButtonColor: '#039D53'
            });
            return false;
        }

        return true;
    }

    const SubmitForm = async () => {

        if (!await runValidation()) return;

        loader.startLoading();

        handleClose();
        const resp = await handleSubmit(reason);

        loader.finishLoading();

        // return;

        if (resp.status) {
            setReason("");


            const alert = await Swal.fire({
                icon: 'success',
                title: 'Confirmación Pre-registro',
                text: 'Convención Nacional de Dirigentes | Dirección Central',
                confirmButtonColor: '#039D53',
                showCancelButton: true,
                showCloseButton: true,
                confirmButtonText: 'Descargar registro convención DC',
                customClass: {
                    confirmButton: 'btn btn-success', // Style the confirm button as needed
                },
                preConfirm: () => {
                    window.open(cofirmationLink, '_blank');
                },
                cancelButtonText: 'Cerrar'
            });

            setAlreadyConfirmed(true);
            // navigate("/");

        } else {
            Swal.fire({
                icon: 'error',
                title: '¡Oops!',
                text: 'Se produjo un error. Por favor, intenta nuevamente más tarde.',
                confirmButtonColor: '#039D53'
            });
        }

        // if (formRef.current.checkValidity()) {
        // } else {
        //     formRef.current.reportValidity(); // Display validation error messages
        // }

    }

    const { ComesFromSysgel } = useComesFromSysgel()



    const DefaultFooter = (
        <div className='d-flex justify-content-end'>
            <Button variant="secondary" style={{ marginRight: 14 }}
                onClick={() => { window.location.reload() }}>
                Cerrar
            </Button>
            {!AlreadyConfirmed && <Button variant="success" onClick={SubmitForm}>
                Guardar cambios
            </Button>}
        </div>);

    const ContactAdminFooter = (
        <Row>
            <Col md={7}>
                <a target='_blank' style={{ textDecoration: "none" }} href='https://api.whatsapp.com/send?phone=8294524663'>
                    <p className="fw-bold text-danger fs-6">CLIC AQUÍ PARA CONTACTAR AL ADMINISTRADOR POR WHATSAPP</p>
                </a>
            </Col>
            <Col md={5}>
                <Button variant="secondary" style={{ marginRight: 14 }}
                    onClick={() => { window.location.reload() }}>
                    Cerrar
                </Button>
                <Button
                    target='_blank'
                    variant='success'
                    href={cofirmationLink}>
                    Descargar registro convención DC
                </Button>
            </Col>
        </Row>
    );


    return (
        <>
            {!AlreadyConfirmed ? DefaultFooter : ContactAdminFooter}

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Guardar cambios</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group
                            className="mb-3"
                            controlId="reason-why-changes-id"
                        >
                            <Form.Label>
                                ¿Por qué desea hacer estos cambios?
                            </Form.Label>
                            <Form.Control
                                value={reason}
                                onChange={(ev) => setReason(ev.target.value)}
                                name='reason_why_changes'
                                as="textarea" rows={3}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cerrar
                    </Button>
                    <Button disabled={isReasonEmpty} variant="success" onClick={SubmitForm}>
                        Guardar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ReasonForChangeDialog;