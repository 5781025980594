import React from 'react'
import { Card, Col, Container, Form, Row } from 'react-bootstrap'
import { useAspirante } from '../Context/AspiranteContext'
import { useNavigate } from 'react-router-dom';
import Foto from './Foto';
import Informacion from './Informacion';
import PageTitle from '../PageTitle/PageTitle1';
import PageTitle2 from '../PageTitle/PageTitle2';
import { useDireccionCentral } from '../Context/DireccionCentralContext';
import PageTitle3 from '../PageTitle/PageTitle3';

export default function DireccionCentral() {
    const { direccionCentral } = useDireccionCentral();

    return (<div>
        <Container className='pt-3 pb-5'>
            {/* <PageTitle title="VERIFICACIÓN ASPIRANTE A CARGOS ELECTIVOS - ELECCIONES 2024" /> */}

            <Card>
                <div className='cintillo fw-bold'>
                    MIEMBRO DIRECCION CENTRAL
                </div>
                <Card.Body>
                    {/* <PageTitle title={"Operativo Organizacional".toUpperCase()} />
                    <PageTitle2 title={"Formulario de confirmación de datos".toUpperCase()} /> */}

                    <PageTitle title={"OPERATIVO DE CONVOCATORIA | Pre-Registro".toUpperCase()} />
                    <PageTitle2 title={"Convención Nacional de Dirigentes | Dirección Central".toUpperCase()} />

                    <PageTitle3 title={"Sábado 28 Octubre, 2023 | 10:00 AM | Pabellón de la Fama".toUpperCase()} />
                    {/* <PageTitle title={' 00'+aspirante?.orden} /> */}
                </Card.Body>
                <Card.Body>
                    <Informacion {...direccionCentral} />
                </Card.Body>
            </Card>
        </Container>
    </div>
    )
}