import { Col, Row } from "react-bootstrap"
import { TextInput } from "../Components/TextInput"
import { useAlreadyConfirmed } from "../Context/AlreadyConfirmed";

export const RedesSociales = (props: any) => {
    const {AlreadyConfirmed} = useAlreadyConfirmed();

    return (
        <div>
            <Row>
                <Col md={6}>
                    <TextInput disabled={AlreadyConfirmed} value={props?.twitter} name={"twitter"} label={"Twitter"} />
                </Col>
                <Col md={6}>
                    <TextInput disabled={AlreadyConfirmed} value={props?.facebook} name={"facebook"} label={"Facebook"} />
                </Col>
            </Row>

            <Row>
                <Col md={6}>
                    <TextInput disabled={AlreadyConfirmed} value={props?.whatsapp} name={"whatsapp"} label={"Whatsapp"} />
                </Col>
                <Col md={6}>
                    <TextInput disabled={AlreadyConfirmed} value={props?.instagram} name={"instagram"} label={"Instagram"} />
                </Col>
            </Row>
        </div>
    )
}
