import React from 'react'
import CheckCedula from '../CheckCedula/CheckCedula';
import { useDireccionCentral } from '../Context/DireccionCentralContext';
import DireccionCentral from '../DireccionCentral';
import { useLoader } from '../Context/LoaderContext';

function Router() {
    const {direccionCentral} = useDireccionCentral();
    
    if(!direccionCentral){
        return <CheckCedula />
    }
    
    return <DireccionCentral />
}

export default Router