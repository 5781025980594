import React, { createContext, useState, useContext, ReactNode } from "react";

interface AspiranteContextProps {
    aspirante: any;
    setAspirante: React.Dispatch<any>;
}

const AspiranteContext = createContext<AspiranteContextProps>({} as any);

interface AspiranteProviderProps {
  children: ReactNode;
}

const AspiranteProvider: React.FC<AspiranteProviderProps> = ({ children }) => {

  const [aspirante, setAspirante] = useState<any>(null);

  const value = {
    aspirante,
    setAspirante,
  };

  return (
    <AspiranteContext.Provider value={value}>
      {children}
    </AspiranteContext.Provider>
  );
};

export const useAspirante = () => useContext(AspiranteContext);

export { AspiranteProvider };
