import React from 'react'
import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap'
import InputMask from "react-input-mask";
// import { BsXOctagonFill, BsCheckCircleFill } from 'react-icons/bs';
import useCheckCedula from '../Hooks/useCheckCedula';
import Registrese from './Registrese';
import PageTitle from '../PageTitle/PageTitle1';
import PageTitle2 from '../PageTitle/PageTitle2';
import PageTitle3 from '../PageTitle/PageTitle3';

function CheckCedula() {

    const { submitForm, errorMessage } = useCheckCedula();



    return (
        <Container className='pt-3'>
            {/* <PageTitle title="VERIFICACIÓN ASPIRANTE A CARGOS ELECTIVOS - ELECCIONES 2024" /> */}
            {/* <PageTitle title={"Operativo Organizacional".toUpperCase()} />
            <PageTitle2 title={"ACTUALIZACÍON DE DATOS | DIRECCIÓN CENTRAL FP".toUpperCase()} /> */}

            <PageTitle title={"OPERATIVO DE CONVOCATORIA | Pre-Registro".toUpperCase()} />
            <PageTitle2 title={"Convención Nacional de Dirigentes | Dirección Central".toUpperCase()} />

            <PageTitle3 title={"Sábado 28 Octubre, 2023 | 10:00 AM | Pabellón de la Fama".toUpperCase()} />

            <PageTitle2 title="! Con la Fuerza del Pueblo Venceremos !" />
            {/* <PageTitle2 title="Ingrese su número cédula y fecha de nacimiento para validar su información electoral" /> */}
            <p className='mb-1'>Ingrese su número cédula y fecha de nacimiento para validar su información electoral:</p>
            <Row className='pt-3'>
                <Col lg={4}>
                    <Form onSubmit={submitForm}>

                        <Form.Group className='mb-3' controlId='Cedula'>
                            <Form.Label>Cédula</Form.Label>
                            <InputMask
                                name='cedula'
                                className='form-control form-control-lg'
                                mask='999-9999999-9'
                                maskChar=''
                                required
                                alwaysShowMask={false}
                                id="cedula"
                            />

                        </Form.Group>
                        {/* 
                        <Form.Group className='mb-3' controlId='Dob'>
                            <Form.Label>Fecha de nacimiento</Form.Label>
                            <Form.Control
                                type='date'
                                name='dob'
                                autoComplete='off'
                                required
                            />

                            <Form.Control.Feedback >
                                Digite su fecha correctamente
                            </Form.Control.Feedback>
                        </Form.Group> */}
                        <Button
                            disabled={false}
                            className='btn-main-form'
                            variant='primary'
                            // href={`/form/${paramOne}/${cedula}`}
                            type='submit'
                        >
                            Continuar
                        </Button>

                        {errorMessage && <p className='mt-2' style={{ 'color': '#dc3545' }}>{errorMessage}</p>}

                    </Form>
                </Col>
            </Row>
        </Container>
    )
}

export default CheckCedula