import { useEffect, useState } from "react";
import { useAlreadyConfirmed } from "../Context/AlreadyConfirmed";
import { RadioInput } from "../Components/RadioInput";

export function PartidoAnteriorRadioInput(props: any) {

    const [selectedOption, setSelectedOption] = useState(0);
    const { AlreadyConfirmed } = useAlreadyConfirmed();
    useEffect(() => {
        if (props?.trabaja) {
            setSelectedOption(props?.partido_anterior_id)
        }
    }, [props?.trabaja]);

    const options = [
        { label: 'PRM', value: 1 },
        { label: 'PLD', value: 2 },
        { label: 'PRSC', value: 3 },
        { label: 'PRD', value: 4 },
        { label: 'Otro', value: 5 },
        { label: 'Ninguno', value: 6 },
    ];

    const handleOptionChange = (event: any) => {
        setSelectedOption(event.target.value);
    };

    return (
        <div>
            <label className="form-label">Partido Anterior</label>
            <br />
            {options.map((option) => (
                <RadioInput
                    key={`partido_anterior_${option.value}`}
                    disabled={AlreadyConfirmed}
                    label={option.label}
                    value={option.value}
                    checked={selectedOption == option.value}
                    onChange={handleOptionChange}
                    name="partido_anterior_id"
                    id={`partido_anterior_${option.value}`}
                />
            ))}
        </div>
    );

}