import './App.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-bootstrap-typeahead/css/Typeahead.bs5.css';
import Header from './Header/Header';
import Footer from './Footer/Footer';
import { LoaderProvider } from "./Context/LoaderContext";
import Router from './Router/Router';
import { CargoLocalidadProvider } from './Context/CargoLocalidadContext';
import { ComesFromSysgelProvider } from './Context/ComesFromSysgelContext';
import { DireccionCentralProvider } from './Context/DireccionCentralContext';
import { AlreadyConfirmedProvider } from './Context/AlreadyConfirmed';

function App() {
  return (
    <LoaderProvider>
      <DireccionCentralProvider>
        <ComesFromSysgelProvider >
          <AlreadyConfirmedProvider>
            <CargoLocalidadProvider >
              <Header />
              <Router />
              <Footer />
            </CargoLocalidadProvider>
          </AlreadyConfirmedProvider>
        </ComesFromSysgelProvider>
      </DireccionCentralProvider>
    </LoaderProvider>
  );
}

export default App;
