import { useEffect, useState } from "react";
import { useAspirante } from "../Context/AspiranteContext";
import { useLoader } from "../Context/LoaderContext";
import formatCedula from "../Helper/formatCedula";
import Service from "../Service/Service";
import { useNavigate } from "react-router-dom";
import { useDireccionCentral } from "../Context/DireccionCentralContext";



export default function useCheckCedula() {

    // Loader context
    const loader = useLoader();
    const { setDireccionCentral } = useDireccionCentral();
    const [errorMessage, setErrorMessage] = useState("");

    async function submitForm(event: any) {
        setErrorMessage("");

        event.preventDefault();
        const form = event.target;

        loader.startLoading();

        try {
            let resp = await Service.getCedulaInfo({
                cedula: formatCedula(form.cedula.value),
                // dob: form.dob.value
            });
            setDireccionCentral({ ...resp.data });

        } catch (error: any) {
            console.error(error);
            const errorMsg = error?.response?.data?.message;
            if (errorMsg) {
                setErrorMessage(errorMsg);
            } else {
                setErrorMessage("Error de conexión.");
            }
        }

        loader.finishLoading();
    }


    return {
        errorMessage,
        submitForm,
    }
}

