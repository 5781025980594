import React, { createContext, useState, useContext, ReactNode } from "react";
import LoaderOverlay from "../LoaderOverlay/LoaderOverlay";

interface LoaderContextProps {
  loadingCount: number;
  startLoading: () => void;
  finishLoading: () => void;
}

const LoaderContext = createContext<LoaderContextProps>({
  loadingCount: 0,
  startLoading: () => {},
  finishLoading: () => {}
});

interface LoaderProviderProps {
  children: ReactNode;
}

const LoaderProvider: React.FC<LoaderProviderProps> = ({ children }) => {

  const [loadingCount, setLoadingCount] = useState(0);

  const startLoading = () => {
    setLoadingCount(prevCount => prevCount + 1);
  };

  const finishLoading = () => {
    setLoadingCount(prevCount => prevCount - 1);
  };


  const value = {
    loadingCount,
    startLoading,
    finishLoading
  };

  return (
    <LoaderContext.Provider value={value}>
      <LoaderOverlay />
      {children}
    </LoaderContext.Provider>
  );
};

const useLoader = () => useContext(LoaderContext);

export { LoaderProvider, useLoader };
