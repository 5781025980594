import { useEffect, useState } from "react"
import Service from "../Service/Service"

function Foto({...props}:any) {

  const [foto,setFoto] = useState("");

  useEffect(()=>{
    Service.photo(props.cedula)
    .then((foto)=>{
      setFoto(foto);
    });
  },[]);

  // console.log({foto})


  return (
    <div className='foto_container' dangerouslySetInnerHTML={{ __html: foto }}>
    </div>
  )
}

export default Foto