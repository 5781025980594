import React from 'react'

function PageTitle3({ title }: any) {
    return (
        <p style={{marginBottom:"7px"}}>
            {title}
        </p>
    );
}

export default PageTitle3