import React, { createContext, useState, useContext, ReactNode } from "react";

interface ReasonForChangeContextProps {
    handleClose: () => void;
    handleShow: () => void;
    show: boolean;
}

const ReasonForChangeContext = createContext<ReasonForChangeContextProps>({} as any);

interface ReasonForChangeProviderProps {
    children: ReactNode;
}

const ReasonForChangeProvider: React.FC<ReasonForChangeProviderProps> = ({ children }) => {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    
    const value = {
        handleClose,
        handleShow,
        show
    };

    return (
        <ReasonForChangeContext.Provider value={value}>
            {children}
        </ReasonForChangeContext.Provider>
    );
};

const useReasonForChange = () => useContext(ReasonForChangeContext);

export { ReasonForChangeProvider, useReasonForChange };
