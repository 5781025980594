import axios from "axios";
import { useEffect, useRef, useState } from "react";
import Service, { url } from "../Service/Service";
import { Col, Form, Row } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { useExterior } from "../Context/ExteriorContext.";
import { useAlreadyConfirmed } from "../Context/AlreadyConfirmed";



const CalleDireccionExterior = (props: any) => {

    const [value, setValue] = useState("");

    useEffect(() => {
        setValue(props.res_exterior_direccion);
    }, []);

    return (
        <Form.Group
            className='mb-3'
            controlId='res_exterior_direccion'
        >
            <Form.Label>Dirección</Form.Label>
            <Form.Control
                onChange={(ev: any) => setValue(ev.target.value)}
                value={value}
                as='textarea'
                rows={1}
                autoComplete='off'
                // required={exterior}
                placeholder="CALLE | NO"
                name='res_exterior_direccion' />

        </Form.Group>
    )
}

export const LocalidadResExterior = (props: any) => {

    const [loaded, setLoaded] = useState(false);

    const {AlreadyConfirmed} = useAlreadyConfirmed();

    const refPaises = useRef(null as any);
    const refEstados = useRef(null as any);
    const refCondados = useRef(null as any);
    const refCiudades = useRef(null as any);


    const [exteriorCirc, setExteriorCirc] = useState([] as any);
    const [pais, setPais] = useState([] as any);
    const [estado, setEstado] = useState([] as any);
    const [condado, setCondado] = useState([] as any);
    const [ciudad, setCiudad] = useState([] as any);


    const [exteriorCircs, setExteriorCircs] = useState([]);
    const [paises, setPaises] = useState([]);
    const [estados, setEstados] = useState([]);
    const [condados, setCondados] = useState([]);
    const [ciudades, setCiudades] = useState([]);


    const handlerExteriorCirc = async (e: any) => {
        // debugger;
        if (e.length) {
            setExteriorCirc(e);

            try {
                const data = await axios.get(
                    `${url}/proyecciones/paises?circunscripcion=${e[0]?.circunscripcion}`
                );

                if (data.status === 200) {
                    if (data.data.length > 0) {
                        setPaises(data.data);
                    } else {
                        setPaises([]);
                    }
                }
            } catch (error) {
                // console.log(error);
            }

            refPaises.current?.clear();
            refEstados.current?.clear();
            refCondados.current?.clear();
            refCiudades.current?.clear();
        } else {
            setExteriorCirc([]);
        }
    };

    const handlerPaises = async (e: any) => {
        if (e.length) {
            setPais(e);

            try {
                const data = await axios.get(
                    `${url}/proyecciones/estados?circunscripcion=${exteriorCirc[0]?.circunscripcion}&pais=${e[0]?.id}}`
                );

                if (data.status === 200) {
                    if (data.data.length > 0) {
                        setEstados(data.data);
                    } else {
                        setEstados([]);
                    }
                }
            } catch (error) {
                // console.log(error);
            }


            refEstados.current?.clear();
            refCondados.current?.clear();
            refCiudades.current?.clear();
        } else {
            setPais([]);
        }

    };

    const handlerEstados = async (e: any) => {
        if (e.length) {
            setEstado(e);

            try {
                const data = await axios.get(
                    `${url}/proyecciones/condados?estado=${e[0]?.id}&pais=${pais[0]?.id}`
                );

                if (data.status === 200) {
                    if (data.data.length > 0) {
                        setCondados(data.data);
                    } else {
                        setCondados([]);
                    }
                }
            } catch (error) {
                // console.log(error);
            }


            refCondados.current?.clear();
            refCiudades.current?.clear();
            //  refMunicipio.current?.clear();
            //  refDm.current?.clear();
            //  refSector.current?.clear();
        } else {
            setEstado([]);
        }
    };

    const handlerCondados = async (e: any) => {
        if (e.length) {
            setCondado(e);

            try {
                const data = await axios.get(
                    `${url}/proyecciones/ciudades?condado=${e[0]?.id}&pais=${pais[0]?.id}}`
                );

                if (data.status === 200) {
                    if (data.data.length > 0) {
                        setCiudades(data.data);
                    } else {
                        setCiudades([]);
                    }
                }
            } catch (error) {
                // console.log(error);
            }

            refCiudades.current?.clear();
            //  refMunicipio.current?.clear();
            //  refDm.current?.clear();
            //  refSector.current?.clear();
        } else {
            setCondado([]);
        }
    };

    const handlerCiudades = async (e: string | any[]) => {
        if (e.length) {
            setCiudad(e);

            // try {
            //   const data = await axios.get(
            //     `${url}/proyecciones/estados?circunscripcion=${exteriorCirc[0]?.circunscripcion}&pais=${pais[0]?.id}}`
            //   );

            //   if (data.status === 200) {
            //     if (data.data.length > 0) {
            //       setPaises(data.data);
            //     } else {
            //       setPaises([]);
            //     }
            //   }
            // } catch (error) {
            //   // console.log(error);
            // }

            // refPaises.current?.clear();
            //  refMunicipio.current?.clear();
            //  refDm.current?.clear();
            //  refSector.current?.clear();
        } else {
            setCiudad([]);
        }
    };

    useEffect(() => {
        const fetchData = async () => {

            const circs = await Service.circExterior();
            setExteriorCircs(circs);

            const circ = circs.filter((v: any) => v.circunscripcion == props.circunscripcion_dir);

            setExteriorCirc(circ);

            // Cargar paises.
            if (props.circunscripcion_dir) {
                const paises = await Service.getPaises(props.circunscripcion_dir);
                setPaises(paises);
                const pais = paises.filter((e: any) => e.id == props.codigo_pais_dir);

                setPais(pais);
            }

            // Cargar Nivel 1.
            if (props.circunscripcion_dir && props.codigo_pais_dir) {
                const niveles1 = await Service.getNivel1(props.circunscripcion_dir, props.codigo_pais_dir);
                setEstados(niveles1);

                const estado = niveles1.filter((v: any) => v.id == props.nivel_1_dir);
                setEstado(estado);
                // debugger;


            }

            // Cargar Nivel 3.
            if (props.nivel_2_dir && props.codigo_pais_dir) {
                const niveles3 = await Service.getNivel3(props.nivel_2_dir, props.codigo_pais_dir);
                setCiudades(niveles3);
                const nivel_3 = niveles3.filter((v: any) => v.id == props.nivel_3_dir);
                // debugger;
                setCiudad(nivel_3);

            }

            // Cargar Nivel 2
            if (props.nivel_2_dir) {
                const niveles2 = await Service.getNivel2(props.nivel_1_dir, props.codigo_pais_dir);
                setCondados(niveles2);
                const nivel_2 = niveles2.filter((v: any) => v.id == props.nivel_2_dir);
                setCondado(nivel_2);
            }

            setLoaded(true);
        }
        fetchData();
    }, []);

    const { isExteriror } = useExterior();


    if (!loaded || !isExteriror) return null;


    return (
        <div>

            <input readOnly  name="exterior_dir" value={isExteriror ? "1" : "0"} hidden />
            <input readOnly name="circunscripcion_dir" value={exteriorCirc.length > 0 ? exteriorCirc[0]?.circunscripcion : ""} hidden />
            <input readOnly name="codigo_pais_dir" value={pais.length > 0 ? pais[0]?.id : ""} hidden />
            <input readOnly name="nivel_1_dir" value={estado.length > 0 ? estado[0]?.id : ""} hidden />
            <input readOnly name="nivel_2_dir" value={condado.length > 0 ? condado[0]?.id : ""} hidden />
            <input readOnly name="nivel_3_dir" value={ciudad.length > 0 ? ciudad[0]?.id : ""} hidden />

            <Row>
                <Col className='mb-3' lg={12}>
                    <Form.Group>
                        <Form.Label>Circ.</Form.Label>
                        <Typeahead
                        disabled={AlreadyConfirmed}
                            clearButton
                            defaultSelected={exteriorCirc}
                            id='ext-circ-id'
                            onChange={handlerExteriorCirc}
                            // labelKey='circunscripcion'
                            labelKey={(option: any) => `${option.circunscripcion}`}
                            placeholder='Seleccionar...'
                            options={exteriorCircs} 

                            
                            />
                    </Form.Group>
                </Col>


            </Row>
            <Row>
                <Col className='mb-3' lg={6}>
                    <Form.Group>
                        <Form.Label>Pais</Form.Label>
                        <Typeahead
                         disabled={AlreadyConfirmed}
                            clearButton
                            id='ext-pais-id'
                            defaultSelected={pais}
                            ref={refPaises}
                            onChange={handlerPaises}
                            labelKey='descripcion'
                            placeholder='Seleccionar...'
                            // inputProps={{ name: "codigo_pais" }}
                            options={paises} />
                    </Form.Group>
                </Col>
                <Col className='mb-3' lg={6}>
                    <Form.Group>
                        <Form.Label>Estado/Comunidad Autónoma</Form.Label>
                        <Typeahead
                         disabled={AlreadyConfirmed}
                            clearButton
                            id='ext-estado-id'
                            defaultSelected={estado}
                            ref={refEstados}
                            onChange={handlerEstados}
                            labelKey='descripcion'
                            placeholder='Seleccionar...'
                            // inputProps={{ name: "nivel_1" }}
                            options={estados} />
                    </Form.Group>
                </Col>

            </Row>
            <Row>
                <Col className='mb-3' lg={6}>
                    <Form.Group>
                        <Form.Label>Condado/Provincia</Form.Label>
                        <Typeahead
                         disabled={AlreadyConfirmed}
                            clearButton
                            defaultSelected={condado}
                            id='ext-condado-id'
                            ref={refCondados}
                            onChange={handlerCondados}
                            labelKey='descripcion'
                            placeholder='Seleccionar...'
                            // inputProps={{ name: "nivel_2" }}
                            options={condados} />
                    </Form.Group>
                </Col>
                <Col className='mb-3' lg={6}>
                    <Form.Group>
                        <Form.Label>Ciudad</Form.Label>
                        <Typeahead
                         disabled={AlreadyConfirmed}
                            clearButton
                            defaultSelected={ciudad}
                            id='ext-ciudad-id'
                            ref={refCiudades}
                            onChange={handlerCiudades}
                            labelKey='descripcion'
                            placeholder='Seleccionar...'
                            // inputProps={{ name: "nivel_3" }}
                            options={ciudades} />
                    </Form.Group>
                </Col>


            </Row>
            <Row>
                <Col md={6}>
                    <Form.Group
                        className='mb-3'
                        controlId='res_exterior_direccion'
                    >
                        <Form.Label>Código postal</Form.Label>
                        <Form.Control
                         disabled={AlreadyConfirmed}
                            defaultValue={props.zipcode}
                            autoComplete='off'
                            placeholder=""
                            name='zipcode' />
                    </Form.Group>
                </Col>
            </Row>
        </div >
    )

}